import React, { useState,useEffect } from "react";
import './App.css';
import { Amplify, Auth, API } from 'aws-amplify'
import awsExports from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App() {
  const [value, setValue] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [idToken, setIdToken] = useState('');

  useEffect(() => {
    const getIdToken = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        setIdToken(idToken);
      } catch (error) {
        console.error('Error retrieving ID token:', error);
      }
    };
    getIdToken();
  }, []);

  function handleChange(event) {
    setLatitude(event.target.value);
  }

  function handleOnChange(event) {
    setLongitude(event.target.value);
  }

  function handleClick() {
    postData(latitude, longitude);
    function postData(latitude, longitude) {
      // console.log(idToken);
      const apiName = 'MyWeatherAPI';
      const path = '/';
      const myInit = {
        body: {
          latitude: latitude,
          longitude: longitude
        },
        headers: {
          'Authorization': `Bearer ${idToken}`
        }, // OPTIONAL
      };

      API.post(apiName, path, myInit)
        .then((response) => {
          if(response.error){
            setValue(response.error);
          }else{
            setValue(response.body[0].temperature);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }



  return (
    <div className="App">
      <header className="App-header">
        <h2>Weather App Content</h2>
        <button onClick={() => Auth.signOut()}>Sign Out</button>
        <h4 >Current Temperature: {value} </h4>
        <input
          onChange={handleChange}
          type="text" placeholder="Enter latitude"
        />
        <input
          onChange={handleOnChange}
          type="text" placeholder="Enter longitude"
        />
        <button onClick={handleClick}>Submit</button>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
