/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:c584b96c-3b3b-4518-ab0b-5e2b709d6d97",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_XyySqFMFV",
    "aws_user_pools_web_client_id": "4q599mvtlod7phr776c03fkrbf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    API: {
        endpoints: [
          {
            name: "MyWeatherAPI",
            endpoint: "https://ety59792u1.execute-api.us-west-2.amazonaws.com/proud/weather",
            service: "lambda",
            region: "us-west-2"
          }

        ]
      }
};


export default awsmobile;
